<template>
  <div class="buyerAfter">
    <el-container>
      <el-header>
        <!-- 导航栏 -->
        <div class="nav">
          <!-- 导航栏logo -->
          <div class="navLogo">
            <img src="../../assets/img/credis-logo.png"
                 alt
                 @click="$router.push('/F_home')" />
            <i :class="bool ? 'el-icon-s-fold' :  'el-icon-s-fold fold'"
               @click="asideCollapse"></i>
          </div>
          <!-- 导航栏右侧 -->
          <div class="navRight">
            <i class="el-icon-chat-line-round"
               @click="roundRouter"></i>
            <i class="el-icon-bell"
               @click="bellRouter"></i>
            <!-- <i class="el-icon-user"
               @click="$router.push('/A_user')"></i> -->
             <el-dropdown>
              <i class="el-icon-user"></i>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item @click.native="$router.push('/A_user')"><i class="el-icon-user-solid" style="color: #ff9933;"></i>Profile</el-dropdown-item>
                <el-dropdown-item @click.native="logout()"><i class="el-icon-lock" style="color: #ff9933;"></i>Logout</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            <!--<el-dialog
              :modal-append-to-body='false'
              :visible.sync="dialogVisible"
              width="30%">
                <el-button @click="dialogVisible=false">Cancel</el-button>
                <el-button type="primary" @click="getLogout">Confirm</el-button>
            </el-dialog>-->
          </div>
        </div>
      </el-header>
      <el-container>
        <!-- 侧栏 -->
        <el-aside :width="width"
                  id="aside"
                  ref="aside">
          <el-menu :default-active="thisIndes"
                   @open="handleOpen"
                   @close="handleClose"
                   router
                   id="menu"
                   ref="menu">
            <!-- Dashboard -->
            <el-menu-item index="/A_dashboard">
              <i class="el-icon-odometer"></i>
              <span slot="title">Dashboard</span>
            </el-menu-item>
            <el-submenu :index="dealList.index">
              <template slot="title"><i :class="dealList.icon"></i> {{ dealList.name }}
              </template>
              <el-menu-item-group v-for="(listItem, i) in dealList.list"
                                  :key="i">
                <el-menu-item :index="listItem.indexName"><i :class="listItem.listIcon"></i>{{ listItem.listName }}
                </el-menu-item>
              </el-menu-item-group>
            </el-submenu>
            <el-submenu :index="$store.state.freebiesList.index">
              <template slot="title"><i :class="$store.state.freebiesList.icon"></i> {{ $store.state.freebiesList.name }}
              </template>
              <el-menu-item-group v-for="(listItem, i) in $store.state.freeList"
                                  :key="i">
                <el-menu-item :index="listItem.indexName"><i :class="listItem.listIcon"></i>{{ listItem.listName }}
                  <span class="num"
                        v-if="listItem.indexBool">{{listItem.indexNumber}}</span>
                </el-menu-item>
              </el-menu-item-group>
            </el-submenu>
            <el-submenu :index="$store.state.othersList.index">
              <template slot="title"><i :class="$store.state.othersList.icon"></i> {{ $store.state.othersList.name }}
              </template>
              <el-menu-item-group v-for="(listItem, i) in $store.state.othList"
                                  :key="i">
                <el-menu-item :index="listItem.indexName"><i :class="listItem.listIcon"></i>{{ listItem.listName }}
                  <span class="num"
                        v-if="listItem.indexBool">{{listItem.indexNumber}}</span>
                </el-menu-item>
              </el-menu-item-group>
            </el-submenu>
            <!-- My Points -->
            <el-menu-item index="/A_myPoints">
              <i class="el-icon-star-off"></i>
              <span slot="title">My Points</span>
            </el-menu-item>
            <!-- Point Cashback -->
            <el-menu-item index="/A_pointCashback">
              <i class="el-icon-star-off"></i>
              <span slot="title">Point Cashback</span>
            </el-menu-item>
            <!-- Report -->
            <el-menu-item index="/A_report">
              <i class="el-icon-warning-outline"></i>
              <span slot="title">Report</span>
            </el-menu-item>
          </el-menu>
        </el-aside>
        <el-main id="main"
                 ref="main">
          <router-view></router-view>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
// 引入接口
// import {
//   findNotificationCounts
// } from '../../api/buyerAfter'
// import '../../assets/less/buyerAfter.css'
// 引入token
import {
  removeToken // 删除token
} from '../../utils/token'
export default {
  data () {
    return {
      dialogVisible: false,
      // 折叠展开
      bool: true,
      width: '230px',
      // 当前显示路由
      thisIndes: '/A_dashboard',
      // 侧栏导航列表
      dealList: {
        name: 'Deal',
        icon: 'el-icon-document-checked',
        index: '1',
        list: [
          {
            listName: 'Search Deals',
            listIcon: 'el-icon-search',
            indexName: '/F_deals'
          },
          {
            listName: 'Deal Requests',
            listIcon: 'el-icon-document-add',
            indexName: '/A_dealsRequests'
          },
          {
            listName: 'Deal Favorites',
            listIcon: 'el-icon-document',
            indexName: '/A_dealFavorites'
          }
        ]
      },
      freeBool: false
    }
  },
  watch: {
    $route: {
      handler: function (val) {
        this.thisIndes = val.path
      },
      deep: true
    }
  },
  created () {
    this.$store.commit('getCount')
  },
  mounted () {
    this.windowRouter()
  },
  methods: {
    handleOpen (key, keyPath) {
      // (key, keyPath)
    },
    handleClose (key, keyPath) {
      // (key, keyPath)
    },
    // 退出登录
    logout () {
      this.$confirm('Are you sure you want to log out?', 'hint', {
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        removeToken()
        this.$router.push('/F_Login')
        localStorage.removeItem('buyer-tokenTime')
        this.$message('Log out') // 弹框提示
        // this.$router.go(0)
        // this.loginSucc = false
        this.$store.commit('getLoginbool', false)
      }).catch(() => {
        this.$message.error('canceled')
      })
    },
    // 退出登录
    // getLogout () {
    //   removeToken()
    //   this.$router.push('/F_Login')
    //   localStorage.removeItem('buyer-tokenTime')
    //   this.$message('Log out') // 弹框提示
    //   // this.$router.go(0)
    //   // this.loginSucc = false
    //   this.$store.commit('getLoginbool', false)
    // },
    // 页面刷新显示当前路由
    windowRouter () {
      var rou = this.$route.path
      this.thisIndes = `${rou}`
      // console.log(this.thisIndes)
    },
    // 导航栏通知跳转
    bellRouter () {
      this.$router.push('/A_messages')
      this.thisIndes = `${this.$route.path}`
    },
    // 导航栏信息跳转
    roundRouter () {
      this.$router.push('/A_chats')
      this.thisIndes = `${this.$route.path}`
    },
    // 侧栏折叠
    asideCollapse () {
      if (this.bool) {
        this.width = '0'
        this.$refs.main.$el.style.left = '0px'
        this.bool = false
      } else {
        this.width = '230px'
        this.$refs.main.$el.style.left = '230px'
        this.bool = true
      }
    }
  }
}
</script>
<style lang="less" scoped>
.buyerAfter {
    height: 100%;

     .is-vertical {
        height: 100%;
    }

     .el-header {
        width: 100%;
        position: fixed;
        top: 0;
        background: #fff;
        border-bottom: 1px solid #c8ced3;
        display: flex;
        align-items: center;
        z-index: 999;
        height: 80px !important;
    }

     .el-main {
        background-color: #e4e5e6;
    }

    // 导航栏
    .nav {
        // height: 100'
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 59px;
        overflow: hidden;
        position: fixed;
        left: 0;

        // 导航栏logo
        .navLogo {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            img {
                cursor: pointer;
                display: block;
                width: 180px;
                height: 100%;
            }

            i {
                margin-left: 5px;
                font-size: 25px;
                color: #73818f;
                transition: all .5s;
            }

            .fold {
                transform: rotateZ(180deg);
            }
        }

        // 导航栏右侧
        .navRight {
            font-size: 25px;
            color: #73818f;
            display: flex;
            justify-content: space-between;
            width: 7%;
            align-content: center;
            margin-right: 20px;
            i {
                // margin: 0 10px;
                cursor: pointer;
            }
        }
    }

    // 侧栏
     .el-aside {
        transition: width .5s;
        position: fixed;
        top: 80px;
        left: 0;
        bottom: 0;
    }

     .el-menu {
        // transition: all .5s;
        border: 0;
        height: 100%;
        border-right: 1px solid #c8ced3;

        .num {
            position: absolute;
            right: 30px;
            top: 50%;
            transform: translateY(-50%);
            display: inline-block;
            padding: 2px 6px;
            border-radius: 50%;
            background-color: #FF6347;
            color: white;
            font-weight: bolder;
            text-align: center;
            line-height: 20px;
            font-size: 14px;
        }

        // overflow-y: auto;
    }

     .el-submenu {
        .el-submenu__title {
            font-weight: bolder;
        }
    }

    .el-submenu__title {
        transition: all 0s;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        &:hover {
            background: #ff9933;
            color: white;

            i {
                color: white;
            }
        }
    }

     .el-menu-item-group__title {
        padding: 0;
    }

     .el-menu-item {
        font-weight: bolder;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        // transition: all .5s;
        &:hover {
            background: #ff9933;
            color: white;

            i {
                color: white;
            }
        }
    }

     .el-menu-item.is-active {
        color: white;
        background-color: #ff9933;
    }

     .el-menu-item {
        padding: 0 20px !important;
    }

    // 内容
     .el-main {
        padding: 0;
        transition: all .5s;
        position: fixed;
        top: 80px;
        left: 230px;
        right: 0;
        bottom: 0;
        text-align: left;
    }
}
</style>
<style scoped>
.buyerAfter >>> .el-submenu__title {
        transition: all 0s;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-weight: bolder;
  }
  .buyerAfter >>> .el-submenu__title:hover {
    background: #ff9933;
          color: white;
  }
  .buyerAfter >>> .el-submenu__title:hover i {
    color: white;
  }
</style>
<style scoped>
.navRight >>> .el-dropdown {
  width: 25px;
  height: 25px;
}
.navRight >>> .el-icon-user {
  width: 25px;
  height: 25px;
  font-size: 25px;
  text-align: center;
  margin: 0;
}
.buyerAfter >>> .el-aside::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}
</style>
